import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query'
import { InputOnChangeData } from 'semantic-ui-react'

export const getMaxPages = (hasNextPage: boolean | undefined, dataPagesLength: number | undefined) =>
  hasNextPage ? (dataPagesLength ?? 0) + 1 : (dataPagesLength ?? 0)

export const processSearch =
  (setSearchValue: React.Dispatch<React.SetStateAction<string>>) =>
  (_event: React.ChangeEvent<HTMLInputElement>, inputData: InputOnChangeData) =>
    setSearchValue(inputData.value)

export interface InfiniteQueryProps<T> {
  dataPagesLength: number
  hasNextPage: boolean | undefined
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<T, Error>>
}

export const processFetchNextPage = <D extends object>(
  activePage: number,
  setActivePage: React.Dispatch<React.SetStateAction<number>>,
  infiniteQueryProps: InfiniteQueryProps<D>
) => {
  const { hasNextPage, fetchNextPage, dataPagesLength } = infiniteQueryProps

  if (activePage > dataPagesLength) {
    if (hasNextPage) {
      fetchNextPage()
    } else {
      setActivePage(dataPagesLength)
    }
  }
}
