import { AxiosError, AxiosResponse } from 'axios'
import * as React from 'react'
import { Breadcrumb, Grid, Icon, Menu, StrictTabProps, Tab, TabPane, TabProps } from 'semantic-ui-react'
import { kebabCase } from 'lodash'
import { Navigate } from 'react-router-dom'
import { formatDocumentTitle } from '../../utils/documentTitleUtils'
import { getTeams, getTeamWebConfig, ITeam, ITeamWebConfig } from '../../actions/Teams'
import {
  cloneTenant,
  deleteTenantAndTeam,
  getCloneTaskStatus,
  getTenant,
  IClonedFileError,
  ICloneTaskStatus,
  IMetadata,
  ITenant,
  removeTenant,
  updateTenant
} from '../../actions/Tenants'
import { VendorClaim } from '../../actions/Utils'
import { createErrorToast, createSuccessToast } from '../alertComponents/Alert'
import { DeleteModal } from '../modalComponents/DeleteModal'
import { SimpleModal } from '../modalComponents/SimpleModal'
import { TenantApiService } from '../newTeams/services/TenantApiService'
import { DynamicMetadataTable } from '../tableComponents/DynamicMetadataTable'
import Env from '../../env/Env'
import { getTeamByTenant, Team } from '../../actions/NewTeams'
import MigrationWarning from '../banners/MigrationWarning'
import { RouterProps } from '../../router/RouterProps'
import { Permissions } from './components/permissions/Permissions'
import { TenantFeatures } from './components/TenantFeatures'
import { TenantInformation } from './components/TenantInformation'
import { Users } from './components/Users/Users'

interface IState {
  tenant?: ITenant
  loading: boolean
  redirect: boolean
  teams: ITeam[]
  team?: ITeam
  cloning: boolean
  teamWebConfig?: ITeamWebConfig
  redirectUrl?: string
  activeTab: string
  panes: string[]
}

export class TenantDetails extends React.PureComponent<RouterProps, IState> {
  constructor(props: RouterProps) {
    super(props)
    this.state = {
      loading: false,
      redirect: false,
      cloning: false,
      teams: [],
      activeTab: 'features',
      panes: []
    }
  }
  async componentDidMount() {
    await this.loadPageInfo()
    await this.checkForNewModelTeam()
    this.initiateTabs()
  }

  async componentDidUpdate(prevProps: RouterProps) {
    const tenantTitle = this.formatTitleFromTeam()

    if (document) {
      document.title = formatDocumentTitle(tenantTitle)
    }

    if (prevProps.params.id !== this.props.params.id) {
      await this.loadPageInfo()
    }
  }

  // This code is adapted from TabLinkingService.tsx and must be updated in both locations
  initiateTabs = () => {
    const urlSearchParams = new URLSearchParams(this.props.location.search)
    this.setState({
      panes: this.getPanes()?.map(pane => kebabCase(pane.menuItem)) || [],
      activeTab: urlSearchParams.get('tab') || 'features'
    })
    this.props.navigate(`${this.props.location.pathname}?tab=${this.state.activeTab}`)
  }

  onTabChange = (_event: React.SyntheticEvent<HTMLElement>, data: TabProps) => {
    const newTab = kebabCase(this.state.panes[(data.activeIndex as number) || 0])
    this.setState({ activeTab: newTab })
    this.props.navigate(`${this.props.location.pathname}?tab=${newTab}`)
  }

  formatTitleFromTeam() {
    if (this.state.team) {
      return `${this.state.team?.name}'s Tenant Details`
    }

    return 'Tenant Details'
  }

  loadPageInfo = async () => {
    this.setState({ loading: true })
    await Promise.all([this.loadTenant(), this.state.teams.length ? Promise.resolve() : this.loadTeams()])
    await this.getTenantTeamInfo()
    this.setState({ loading: false })
  }

  loadTenant = async () => {
    const tenantId = this.props.params.id
    if (tenantId === undefined) {
      createErrorToast(`tenantId not found in URL ${this.props.location.pathname}`)
      return
    }
    try {
      const tenant = await getTenant(tenantId)
      this.setState({ tenant })
    } catch (error: any) {
      if (error.isAxiosError && (error as AxiosError).response?.status === 404) {
        createErrorToast(new Error(`Tenant with id ${tenantId} not found.`), { title: 'Not Found' })
      } else {
        createErrorToast(error)
      }
      this.setState({ redirect: true })
    }
  }

  getTenantTeamInfo = async () =>
    this.getTeamForTenant().then(
      (team: ITeam) => this.getTeamWebConfig(team.name),
      reason => createErrorToast(reason)
    )

  async loadTeams() {
    try {
      const teams = await getTeams()
      this.setState({ teams })
    } catch (error) {
      createErrorToast(error)
    }
  }

  async getTeamForTenant() {
    const filteredTeams = this.state.teams.filter(team => team.id === this.state.tenant?.teamId)
    if (filteredTeams.length === 1) {
      this.setState({ team: filteredTeams[0] })
      return Promise.resolve(filteredTeams[0])
    } else {
      return Promise.reject(
        `No team found for tenant ${this.state.tenant?.tenantId}. The tenant's region cannot be determined, therefore feature flags may not load.`
      )
    }
  }

  async getTeamWebConfig(teamName: string) {
    try {
      const teamWebConfig = await getTeamWebConfig(teamName)
      this.setState({ teamWebConfig })
    } catch (error) {
      createErrorToast(error)
    }
  }

  deleteTenant = () => {
    const { tenant } = this.state
    if (tenant) {
      return deleteTenantAndTeam(tenant.tenantId)
    }
    return Promise.resolve()
  }

  handleDelete = () => {
    const { tenant, team } = this.state
    createSuccessToast(`Successfully deleted tenant ${tenant?.tenantId} and team ${team?.name}.`)
    this.setState({ redirect: true })
  }

  removeTenant = () => {
    const { tenant } = this.state
    if (tenant) {
      return removeTenant(tenant.teamId, tenant.tenantId)
    }
    return Promise.resolve()
  }

  handleRemoval = () => {
    const { tenant, team } = this.state
    createSuccessToast(`Successfully remove tenant ${tenant?.tenantId} from team ${team?.name}.`)
    this.setState({ redirect: true })
  }

  handleUpdateMetadata = (metadata: IMetadata) => updateTenant(this.state.tenant?.tenantId || '', { metadata })

  monitorCloneProgress = async (taskId: number) => {
    const timer = setInterval(async () => {
      try {
        const taskStatus = await getCloneTaskStatus(taskId)
        if (taskStatus.type === 'completed') {
          clearInterval(timer)
          this.handleCloneSuccess(taskStatus)
        }
        if (taskStatus.type === 'failed') {
          this.setState({ cloning: false })
          clearInterval(timer)
          createErrorToast(new Error(taskStatus.reason))
        }
      } catch (error) {
        clearInterval(timer)
        createErrorToast(error)
        this.setState({ cloning: false })
      }
    }, 5000)
  }

  handleCloneSuccess = (taskStatus: ICloneTaskStatus) => {
    this.setState({ cloning: false })
    createSuccessToast(
      `Successfully cloned tenant.\nNew Tenant: ${taskStatus.result?.cloned.tenantId}\nNew Team: ${taskStatus.result?.cloned.name}`
    )
    const errorKeys = Object.keys(taskStatus.result?.files || {}).filter(
      key => (taskStatus.result?.files[key] as IClonedFileError).error
    )
    if (errorKeys.length > 0) {
      createErrorToast(
        new Error(
          'Error Cloning Files:\n' +
            errorKeys
              .map(key => `${key}: ${(taskStatus.result?.files[key] as IClonedFileError).error.message}\n`)
              .reduce((previous, current) => previous + current)
        )
      )
    }
  }

  handleCloneTenant = async () => {
    if (this.state.tenant) {
      const response = await cloneTenant(this.state.tenant?.tenantId)
      this.setState({ cloning: true })
      this.monitorCloneProgress(response.taskId)
      return response
    }
    return {} as AxiosResponse
  }

  checkForNewModelTeam = async (): Promise<void> => {
    const newModelTeam = await this.fetchNewModelTeamByTenantId()

    if (newModelTeam) {
      this.setNewModelRedirect(`/teams/${newModelTeam.id}?banner=true`)()
    }
  }

  fetchNewModelTeamByTenantId = async (): Promise<Team | null> => {
    try {
      const tenantId = this.state.tenant?.tenantId
      if (tenantId) {
        return await getTeamByTenant(tenantId)
      }
      return null
    } catch (error: any) {
      if (error.response.status !== 404) {
        createErrorToast(error)
      }
      return null
    }
  }

  setNewModelRedirect = (redirectUrl: string) => () => {
    this.setState({ redirectUrl })
  }

  getPanes = (): StrictTabProps['panes'] => {
    const { loading, tenant, teamWebConfig } = this.state
    const tenantApiService: TenantApiService = {
      tenantId: tenant?.tenantId || '',
      vendor: tenant?.vendor,
      teamAPI: teamWebConfig?.server.api || Env.API,
      parentLoading: loading
    }

    const featuresTab = {
      menuItem: 'Features',
      render: () => (
        <TabPane>
          <TenantFeatures tenantApi={tenantApiService} />
        </TabPane>
      )
    }
    const metadataTab = {
      menuItem: 'Metadata',
      render: () => (
        <TabPane>
          <DynamicMetadataTable
            loading={loading}
            updateMetadata={this.handleUpdateMetadata}
            updateData={this.loadTenant}
            metadata={tenant?.metadata}
          />
        </TabPane>
      )
    }
    const permissionsTab = {
      menuItem: 'Permissions',
      render: () => (
        <TabPane>
          <Permissions tenantId={tenant?.tenantId} />
        </TabPane>
      )
    }
    const usersTab = {
      menuItem: 'Users',
      render: () => (
        <TabPane>
          <Users tenantId={tenant?.tenantId} teamApi={teamWebConfig?.server.api} vendor={tenant?.vendor} />
        </TabPane>
      )
    }
    return [featuresTab, metadataTab, usersTab, ...(tenant?.vendor === VendorClaim.Salesforce ? [permissionsTab] : [])]
  }

  render() {
    const { redirect, redirectUrl, tenant, team, loading } = this.state

    if (redirectUrl) {
      return <Navigate to={redirectUrl} />
    }

    return (
      <div className="route-component">
        {redirect && <Navigate to="/old-model/tenants" />}
        <MigrationWarning />
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => this.props.navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            {tenant?.vendor === 'skedulo' && !tenant?.parentTenantId && (
              <Menu.Item>
                <SimpleModal
                  submitFn={this.handleCloneTenant}
                  header={'Clone Tenant'}
                  content={`Are you sure you want to clone the tenant ${tenant?.tenantId} for team ${team?.name}.`}
                  successMessage={`Cloning for Tenant ${tenant?.tenantId} has started.`}
                  globalLoading={this.state.cloning}
                />
              </Menu.Item>
            )}
            <Menu.Item>
              {tenant?.parentTenantId ? (
                <DeleteModal
                  deleteMethod={this.deleteTenant}
                  updateData={this.handleDelete}
                  content={`Are you sure you want to delete the tenant ${tenant?.tenantId} and the team ${team?.name}. This will permanently remove all associated data.`}
                  type="Tenant"
                  buttonText={'Delete Tenant'}
                />
              ) : (
                <DeleteModal
                  deleteMethod={this.removeTenant}
                  updateData={this.handleRemoval}
                  content={`Are you sure you want to remove the tenant ${tenant?.tenantId} from the team ${team?.name}`}
                  type="Tenant"
                  buttonText={'Remove Tenant'}
                />
              )}
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={5} style={{ height: '100%' }} className="scrollable">
            <TenantInformation loadTenant={this.loadTenant} loading={loading} team={team} tenant={tenant} />
          </Grid.Column>
          <Grid.Column width={11} stretched style={{ height: '100%' }} className="scrollable">
            <Tab
              panes={this.getPanes()}
              onTabChange={this.onTabChange}
              activeIndex={this.state.panes.indexOf(this.state.activeTab)}
            />
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
