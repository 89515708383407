import { Link } from 'react-router-dom'
import { Button, Popup } from 'semantic-ui-react'
import { formatDate } from '../../../utils/dateUtils'
import { IPackageWithTenantCount, IPackageVersionWithTenantCount } from '../../../actions/Package'

export interface IField {
  title: string
  viewComponent: JSX.Element
  editComponent: JSX.Element
}

export const getPackageDetailsFields = (packageData: IPackageWithTenantCount | undefined): IField[] => {
  if (packageData) {
    const {
      packageName,
      createdBy,
      createdAt,
      latestVersion,
      latestCreatedAt,
      latestVersionDescription,
      latestCreatedBy,
      count
    } = packageData

    return [
      {
        title: 'Name',
        viewComponent: <>{packageName}</>,
        editComponent: <input type="text" value={packageName} onChange={() => null} />
      },
      {
        title: 'Total teams',
        viewComponent: <>{count}</>,
        editComponent: <input type="text" value={count} onChange={() => null} />
      },
      {
        title: 'Latest version',
        viewComponent: <>{latestVersion}</>,
        editComponent: <input type="text" value={latestVersion} onChange={() => null} />
      },
      {
        title: 'Latest version description',
        viewComponent: <>{latestVersionDescription}</>,
        editComponent: <input type="text" value={latestVersionDescription} onChange={() => null} />
      },
      {
        title: 'Created',
        viewComponent: <>{formatDate(createdAt, 'MMM DD, YYYY - hh:mma')}</>,
        editComponent: <input type="text" value={createdAt} onChange={() => null} />
      },
      {
        title: 'Created by',
        viewComponent: <>{createdBy}</>,
        editComponent: <input type="text" value={createdBy} onChange={() => null} />
      },
      {
        title: 'Latest created',
        viewComponent: <>{formatDate(latestCreatedAt, 'MMM DD, YYYY - hh:mma')}</>,
        editComponent: <input type="text" value={latestCreatedAt} onChange={() => null} />
      },
      {
        title: 'Latest created by',
        viewComponent: <>{latestCreatedBy}</>,
        editComponent: <input type="text" value={latestCreatedBy} onChange={() => null} />
      }
    ]
  }

  return []
}

export const getPackageVersionDetailsFields = (
  packageVersionData: IPackageVersionWithTenantCount | undefined,
  toggleModalIsOpen: () => void
): IField[] => {
  if (packageVersionData) {
    const { createdBy, createdAt, description, count, packageName, version, sourceUrl, artifacts } = packageVersionData

    return [
      {
        title: 'Name',
        viewComponent: <>{packageName}</>,
        editComponent: <>{packageName}</>
      },
      {
        title: 'Version',
        viewComponent: <>{version}</>,
        editComponent: <>{version}</>
      },
      {
        title: 'Total teams',
        viewComponent: <>{count}</>,
        editComponent: <>{count}</>
      },
      {
        title: 'Description',
        viewComponent: <>{description}</>,
        editComponent: <>{description}</>
      },
      {
        title: 'Source URL',
        viewComponent: (
          <Link to={sourceUrl} target="_blank" className="table-cell-overflow">
            {sourceUrl}
          </Link>
        ),
        editComponent: <>{sourceUrl}</>
      },
      {
        title: 'Artifacts',
        viewComponent: (
          <Popup
            trigger={
              <div>
                <Button
                  disabled={!artifacts || artifacts.length === 0}
                  onClick={toggleModalIsOpen}
                  content="Inspect artifacts"
                />
              </div>
            }
            content="Artifact manifest not available for this version. Register a new version to generate a manifest."
            disabled={artifacts?.length > 0}
          />
        ),
        editComponent: <></>
      },
      {
        title: 'Created',
        viewComponent: <>{formatDate(createdAt, 'MMM DD, YYYY - hh:mma')}</>,
        editComponent: <>{createdAt}</>
      },
      {
        title: 'Created by',
        viewComponent: <>{createdBy}</>,
        editComponent: <>{createdBy}</>
      }
    ]
  }

  return []
}
