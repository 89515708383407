import { Divider, Icon, Popup, Statistic } from 'semantic-ui-react'
import { IUserSummary } from 'src/actions/Tenants'
import { LoaderWrap } from '../../../loadingComponents/LoaderWrap'

export const UserSummary = ({
  data,
  loading,
  isSummaryForCustomer,
  metadata
}: {
  data: IUserSummary[]
  loading: boolean
  isSummaryForCustomer?: boolean
  metadata?: Record<string, any>
}) => {
  const MaxActiveUsersEnforced = metadata?.MaxActiveUsersEnforced || 'Not set'
  return (
    <LoaderWrap loading={loading}>
      <div data-testid="user-summary">
        <Statistic.Group size="small" className="ui stackable middle aligned three column grid">
          <Statistic horizontal className="column">
            <Statistic.Value>{data[0].totalActiveResources}</Statistic.Value>
            <Statistic.Label>
              {isSummaryForCustomer ? 'Active Production Resources ' : 'Active Resources '}
              <Popup
                trigger={<Icon name="question circle" />}
                content={
                  <div>
                    A user is counted as a Resource if they are active, have the &apos;Resource&apos; role assigned, and{' '}
                    <b>don&apos;t have access to ANY</b> of the following permissions via their allocated user roles:
                    <ul>
                      <li>Manage resource allocations</li>
                      <li>Dispatch allocated work to resources</li>
                      <li>Create and send job and shift offers</li>
                    </ul>
                  </div>
                }
                basic
                wide
              />
            </Statistic.Label>
          </Statistic>
          <Statistic horizontal className="column">
            <Statistic.Value>{data[0].totalActiveSchedulers}</Statistic.Value>
            <Statistic.Label>
              {isSummaryForCustomer ? 'Active Production Schedulers ' : 'Active Schedulers '}
              <Popup
                trigger={<Icon name="question circle" />}
                content={
                  <div>
                    A user is counted as a Scheduler if they are active and <b>have access to ANY</b> of the following
                    permissions via their allocated user roles:
                    <ul>
                      <li>Manage resource allocations</li>
                      <li>Dispatch allocated work to resources</li>
                      <li>Create and send job and shift offers</li>
                    </ul>
                    Users with the Admin role applied are always counted as a Scheduler.
                  </div>
                }
                basic
                wide
              />
            </Statistic.Label>
          </Statistic>
          {!isSummaryForCustomer && (
            <Statistic horizontal className="right aligned column">
              <Statistic.Label>
                <Popup
                  trigger={<Icon name="question circle" />}
                  content={
                    <div>
                      Active user limits can be setup by navigating to the "Metadata" tab and adding a new property with
                      the following values:
                      <ul>
                        <li>
                          <b>Value:</b> MaxActiveUsersEnforced
                        </li>
                        <li>
                          <b>Type:</b> Number
                        </li>
                      </ul>
                      When setup, the user will receive warnings or error alerts when their Active users exceed the
                      allocations that you input here
                    </div>
                  }
                  basic
                  wide
                  hoverable
                />
                Active User limit:
              </Statistic.Label>
              <Statistic.Value className="small">{MaxActiveUsersEnforced}</Statistic.Value>
            </Statistic>
          )}
        </Statistic.Group>
        <Divider />
      </div>
    </LoaderWrap>
  )
}
