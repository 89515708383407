import { Grid, Header, Tab, TabPane, Breadcrumb, BreadcrumbDivider, BreadcrumbSection } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { useBoolean } from 'usehooks-ts'
import { useGetPackageVersionByQuery } from '../../queries/packages/GetPackageVersionByQuery'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { useGetPackageTenantCountQuery } from '../../queries/packages/GetPackageTenantCountQuery'
import { PackageInformationPanel } from './component/PackageInformationPanel'
import { PackageTenantsList } from './component/PackageTenantsList'
import { getPackageVersionDetailsFields } from './services/FieldUtils'
import {
  combinePackageVersionWithTenantCount,
  generatePackageVersionTenantQuery
} from './services/PackageTenantCountUtils'
import { PackageVersionArtifactsModal } from './component/PackageVersionArtifactsModal'

export const PackageVersionsDetails = () => {
  const { value: modalIsOpen, toggle: toggleModalIsOpen } = useBoolean(false)

  const params = useParams()

  const packageName = params.id!
  const version = params.version!

  const { data: regionsInfo } = useGetRegionQuery()
  const { data: packageVersion, isLoading: isLoadingPackageVersion } = useGetPackageVersionByQuery(packageName, version)
  const { data: packageTenantCount, isLoading: isLoadingPackageVersionTenantCount } = useGetPackageTenantCountQuery(
    regionsInfo || [],
    generatePackageVersionTenantQuery(packageVersion)
  )

  const packageVersionWithTenantCount = combinePackageVersionWithTenantCount(packageVersion, packageTenantCount || [])
  const regionCodes = regionsInfo?.map(regionInfo => regionInfo.region.toUpperCase())

  const panes = regionCodes?.map(regionCode => ({
    menuItem: regionCode,
    render: () => (
      <TabPane>
        <PackageTenantsList region={regionCode} title="Teams" packageName={packageName} packageVersion={version} />
      </TabPane>
    )
  }))

  return (
    <div className="package-component">
      <PackageVersionArtifactsModal
        modalIsOpen={modalIsOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        artifacts={packageVersionWithTenantCount?.artifacts || []}
      />
      <Grid stackable>
        <Grid.Column floated="left" width={5} textAlign="left">
          <Breadcrumb>
            <BreadcrumbSection href="/packages">Packages</BreadcrumbSection>
            <BreadcrumbDivider />
            <BreadcrumbSection href={`/packages/${encodeURIComponent(packageName)}`}>{packageName}</BreadcrumbSection>
          </Breadcrumb>
          <Header as="h2">
            {packageName} - {version}
          </Header>
        </Grid.Column>
      </Grid>
      <Grid stackable style={{ height: 'calc(100% - 50px - 3rem)' }}>
        <Grid.Column width={5} style={{ height: '100%' }} className="scrollable">
          <PackageInformationPanel
            title="Package version details"
            fields={getPackageVersionDetailsFields(packageVersionWithTenantCount, toggleModalIsOpen)}
            showEditButton={false}
            isLoading={isLoadingPackageVersionTenantCount || isLoadingPackageVersion}
          />
        </Grid.Column>
        <Grid.Column width={11} style={{ height: '100%' }} className="scrollable">
          <Tab panes={panes} />
        </Grid.Column>
      </Grid>
    </div>
  )
}
