import { Label } from 'semantic-ui-react'
import { MobileExtensionsStatus, MobileExtensionsStatusColor } from './mobileExtensionsConstants'

const getStatusColor = (status: string) => {
  switch (status) {
    case MobileExtensionsStatus.Published:
      return MobileExtensionsStatusColor.Green
    case MobileExtensionsStatus.Installed:
      return MobileExtensionsStatusColor.Green
    default:
      return MobileExtensionsStatusColor.Grey
  }
}

interface MobileFormStatusProps {
  status: string
}

export const MobileFormStatus = ({ status }: MobileFormStatusProps) => {
  const statusColor = getStatusColor(status)

  return (
    <Label basic color={statusColor} data-testid="mobile-form-status">
      {status}
    </Label>
  )
}
