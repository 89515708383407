import { SemanticWIDTHS } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useGetTeamQuery } from '../../../../queries/GetTeamQuery'
import { useMobileV2FormService } from '../../services/MobileV2FormService'
import { FormDeployContextEnum, IMobileV2Form } from '../../../../actions/MobileV2Forms'
import { createMemoizedColumns } from '../../../../components/TableComponents/ReactTable'
import { MobileExtensionsStatus } from './mobileExtensionsConstants'
import { MobileFormStatus } from './MobileFormStatus'
import { MobileFormsListContainer } from './MobileFormsListContainer'

enum MobileV2FormType {
  Job = 'Job',
  Resource = 'Resource'
}

const FORM_IN_PACKAGE_COLUMN_WIDTH: SemanticWIDTHS = 3
const REVISION_ID_COLUMN_WIDTH: SemanticWIDTHS = 2
const FORM_TYPE_COLUMN_WIDTH: SemanticWIDTHS = 2
export const EMPTY_LIST_MESSAGE = 'No mobile v2 extensions found.'

export const getFormJobTypeList = (form: IMobileV2Form) => form.jobTypes ?? []

export const getFormStatus = (form: IMobileV2Form) => {
  const hasJobTypes = getFormJobTypeList(form)?.length > 0
  return hasJobTypes ? MobileExtensionsStatus.Published : MobileExtensionsStatus.Draft
}

export const getFormType = (form: IMobileV2Form) => {
  const formDefinition = form.formRev?.definition
  const deployContext = formDefinition?.deploy?.context
  return deployContext === FormDeployContextEnum.Resource ? MobileV2FormType.Resource : MobileV2FormType.Job
}

const getJobTypeList = (form: IMobileV2Form) => getFormJobTypeList(form)?.join(', ')

export const getFormRevId = (form: IMobileV2Form) => form.formRev?.id ?? '-'

export const getChildFormNameList = (form: IMobileV2Form) =>
  form.formRev?.definition?.forms.map(item => item.name) ?? []

interface MobileV2FormsProps {
  teamId: string
}

const MobileV2FormNameCell = ({ row }: { row: Row<IMobileV2Form> }) => {
  const childFormNameList = getChildFormNameList(row.original)
  return (
    <div>
      {childFormNameList.map((formName, index) => (
        <p key={`${formName}-${index}`} style={{ marginBottom: '0.5rem' }}>
          {formName}
        </p>
      ))}
    </div>
  )
}

const MobileV2Forms = ({ teamId }: MobileV2FormsProps) => {
  const { data: teamData, isLoading: loadingTeamData } = useGetTeamQuery(teamId)
  const { mobileV2Forms, isLoading: loadingMobileFormList } = useMobileV2FormService({
    tenantId: teamData?.tenantId ?? '',
    region: teamData?.region ?? ''
  })

  const renderStatusCell = ({ row }: { row: Row<IMobileV2Form> }) => (
    <MobileFormStatus status={getFormStatus(row.original)} />
  )

  const tableColumns = createMemoizedColumns<IMobileV2Form>(
    [
      {
        Header: 'Forms in package',
        width: FORM_IN_PACKAGE_COLUMN_WIDTH,
        Cell: MobileV2FormNameCell
      },
      {
        Header: 'Revision ID',
        width: REVISION_ID_COLUMN_WIDTH,
        accessor: getFormRevId
      },
      {
        Header: 'Form type',
        width: FORM_TYPE_COLUMN_WIDTH,
        accessor: getFormType
      },
      {
        Header: 'Job type',
        width: undefined,
        accessor: getJobTypeList
      },
      {
        Header: 'Status',
        width: undefined,
        Cell: renderStatusCell
      }
    ],
    []
  )

  return (
    <div data-testid="mobile-v2-forms-list">
      <MobileFormsListContainer
        title="Mobile v2 extensions"
        description="This section shows the list of custom v2 forms in a team. A package can include one or multiple forms with the same type and settings"
        forms={mobileV2Forms}
        isLoading={loadingTeamData || loadingMobileFormList}
        emptyListMessage={EMPTY_LIST_MESSAGE}
        tableColumns={tableColumns}
      />
    </div>
  )
}

export default MobileV2Forms
