import { groupBy, keys, isEqual } from 'lodash'
import { useState } from 'react'
import { Modal, Grid, Accordion, Icon } from 'semantic-ui-react'
import { Artifact } from '../../../actions/Package'

export const PackageVersionArtifactsModal = ({
  modalIsOpen,
  toggleModalIsOpen,
  artifacts
}: {
  modalIsOpen: boolean
  toggleModalIsOpen: () => void
  artifacts: Artifact[]
}) => {
  const artifactsByType = groupBy(artifacts, 'type')
  const artifactTypes = keys(artifactsByType)
  const [expandedTypes, setExpandedTypes] = useState<string[]>([])
  const isAllExpanded = () => isEqual(artifactTypes, expandedTypes)
  const artifactsLength = artifacts?.length || 0

  const handleTypeClick = (type: string, toggleShowAll?: boolean) => {
    if (toggleShowAll) {
      isAllExpanded() ? setExpandedTypes([]) : setExpandedTypes(artifactTypes)
    } else {
      setExpandedTypes(prev => (prev.includes(type) ? prev.filter(i => i !== type) : [...prev, type]))
    }
  }

  return (
    <Modal open={modalIsOpen} closeOnDimmerClick onClose={toggleModalIsOpen} closeIcon style={{ height: '70%' }}>
      <Modal.Header>Package version artifacts</Modal.Header>
      <Modal.Content style={{ height: '100%' }} className="scrollable">
        <Grid>
          <Grid.Column width={8}>
            <p>
              {artifactsLength} artifact{artifactsLength !== 1 && 's'}
            </p>
          </Grid.Column>
          <Grid.Column textAlign="right" width={8}>
            <a onClick={() => handleTypeClick('', true)} style={{ cursor: 'pointer' }}>
              {isAllExpanded() ? 'Collapse all' : 'Expand all'}
            </a>
          </Grid.Column>
        </Grid>

        <Accordion styled fluid>
          {artifactTypes.map(type => (
            <div key={type}>
              <Accordion.Title onClick={() => handleTypeClick(type)} style={{ color: 'black' }}>
                <Icon name={expandedTypes.includes(type) ? 'caret down' : 'caret right'} />
                {type} ({artifactsByType[type].length})
              </Accordion.Title>
              <Accordion.Content active={expandedTypes.includes(type)} style={{ marginLeft: '12px' }}>
                {artifactsByType[type].map(artifact => (
                  <li key={artifact.identifier}>{artifact.identifier}</li>
                ))}
              </Accordion.Content>
            </div>
          ))}
        </Accordion>
      </Modal.Content>
    </Modal>
  )
}
