import { Link } from 'react-router-dom'
import { Row } from 'react-table'
import { Popup, Icon, Label, Image } from 'semantic-ui-react'
import { environmentColours } from '../../newTeams/component/NewTeamsList'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { TeamWithSummary } from './CustomerUsers'

export const CustomerUsersList = ({ data, loading }: { data?: TeamWithSummary[]; loading?: boolean }) => {
  const UnavailablePopup = (
    <Popup
      trigger={
        <div>
          Unavailable <Icon name="question circle" />
        </div>
      }
      content="Summary cannot be found for this tenant."
      basic
    />
  )
  const teamsColumns = createMemoizedColumns<TeamWithSummary>([
    {
      Header: 'Team',
      accessor: (team: TeamWithSummary) => team.team.primaryName,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) => (
        <Link to={`/teams/${row.original.team.id}?tab=users`}>
          {row.original.team.primaryName}
          {row.original.team.isSalesforce && (
            <Popup
              content="Salesforce Team"
              position="top center"
              trigger={
                <Image
                  inline={true}
                  id="salesforce-logo"
                  src={require('../../../images/salesforce-logo-icon.png')} // Workaround for import.meta.url not playing nice with jest.
                  size="mini"
                  spaced="left"
                  style={{ width: 20 }}
                />
              }
              style={{
                opacity: 0.9,
                textAlign: 'center'
              }}
              inverted
            />
          )}
        </Link>
      )
    },
    {
      Header: 'Environment',
      accessor: (team: TeamWithSummary) => team.team.environment,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) => (
        <Label color={environmentColours[row.original.team.environment] ?? 'grey'} basic>
          {row.original.team.environment}
        </Label>
      )
    },
    {
      Header: 'Active Resources',
      accessor: (team: TeamWithSummary) => team.summary.totalActiveResources,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) =>
        row.original.summary.totalActiveResources !== null ? (
          <>{row.original.summary.totalActiveResources}</>
        ) : (
          UnavailablePopup
        )
    },
    {
      Header: 'Active Schedulers',
      accessor: (team: TeamWithSummary) => team.summary.totalActiveSchedulers,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) =>
        row.original.summary.totalActiveSchedulers !== null ? (
          <>{row.original.summary.totalActiveSchedulers}</>
        ) : (
          UnavailablePopup
        )
    },
    {
      Header: 'Active user limit',
      accessor: (team: TeamWithSummary) => team.team.metadata.MaxActiveUsersEnforced,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) =>
        row.original.team.metadata.MaxActiveUsersEnforced !== null ? (
          <>{row.original.team.metadata.MaxActiveUsersEnforced}</>
        ) : (
          UnavailablePopup
        )
    }
  ])

  return (
    <Table
      loading={loading}
      columns={teamsColumns}
      data={data || []}
      emptyMessage="No teams found"
      color="blue"
      className="sticky-table"
      textAlign="left"
    />
  )
}
