export enum MobileExtensionsStatus {
  Draft = 'Draft',
  Published = 'Published',
  Validated = 'Validated',
  Installed = 'Installed'
}

export enum MobileExtensionsStatusColor {
  Green = 'green',
  Grey = 'grey'
}
