import { Button, Form, Modal, Header, Radio, Input, Popup, Message, Icon } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { useState } from 'react'
import { RadioGroup, RadioGroupOption } from '../../../components/RadioGroup/RadioGroup'
import { useInstallPackageMutation } from '../../../mutations/packages/InstallPackageMutation'
import { PackageInstallationRequest } from '../../../actions/Package'
import { createErrorToast } from '../../../views/alertComponents/Alert'

export interface PackageInstallationModalProps {
  tenantId: string
  region: string
  disableInstallPackages: boolean
  isFetching: boolean
  teamName: string
}

export interface IProductPackage {
  title: string
  packageName: string
}

export const PRODUCT_PACKAGES: RadioGroupOption[] = [
  {
    text: 'Pulse scheduling experience',
    value: 'skedulo-core/pulse-scheduling-experience'
  }
]

export const CORE_PRODUCT_PACKAGE = 'core'
export const CUSTOMISED_PACKAGE = 'customised'

export const INSTALLATION_OPTIONS: RadioGroupOption[] = [
  {
    text: 'Product package',
    value: CORE_PRODUCT_PACKAGE
  },
  {
    text: 'Custom package',
    value: CUSTOMISED_PACKAGE
  }
]

export const PackageInstallationModal = (props: PackageInstallationModalProps) => {
  const { value: open, setTrue: openModal, setFalse: closeModal } = useBoolean(false)
  const { region, tenantId, disableInstallPackages, isFetching, teamName } = props
  const [packageName, setPackageName] = useState('')
  const [packageVersion, setPackageVersion] = useState('')
  const [selectedInstallationOption, setSelectedInstallationOption] = useState('')
  const { mutate: installPackage, isLoading } = useInstallPackageMutation(() => onClose())
  const {
    value: openConfirmation,
    setTrue: openModalConfirmation,
    setFalse: closeModalConfirmation
  } = useBoolean(false)

  const onClose = () => {
    setSelectedInstallationOption('')
    setPackageName('')
    setPackageVersion('')
    closeModal()
    closeModalConfirmation()
  }

  const isValidForm = () => {
    if (region === '') {
      createErrorToast(new Error('Team region is undefined.'), { title: 'Error:' })
      return false
    } else if (selectedInstallationOption === '') {
      createErrorToast(new Error('Package installation mode is undefined.'), { title: 'Error:' })
      return false
    } else if (packageName === '') {
      createErrorToast(new Error('Package name is undefined.'), { title: 'Error:' })
      return false
    }
    return true
  }

  const handleInstall = () => {
    const tenantIds = tenantId ? [tenantId] : []
    const request: PackageInstallationRequest = {
      packageName,
      version: packageVersion,
      tenantIds
    }
    installPackage({ request, region })
  }

  const handleSubmit = () => {
    if (isValidForm()) {
      openModalConfirmation()
    }
  }

  const confirmationModal = () => (
    <Modal open={openConfirmation} onClose={closeModalConfirmation} data-testid="package-install-confirmation-modal">
      <Modal.Header id="modal-header-install-package">Install package</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to install package{' '}
          <b>
            {packageName}
            {packageVersion ? ' v' + packageVersion : ' (latest version)'}
          </b>{' '}
          to team <b>{teamName}</b>?
        </p>
        <Message error>
          <Icon name="exclamation triangle" size="large" />
          This action may be irreversible. Please ensure you are confident before proceeding.
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModalConfirmation} content="Cancel" />
        <Button negative id="confirm-modal-install" content="Install" onClick={handleInstall} disabled={isLoading} />
      </Modal.Actions>
    </Modal>
  )

  return openConfirmation ? (
    confirmationModal()
  ) : (
    <Modal
      data-testid="package-installation-modal"
      closeIcon
      open={open}
      trigger={
        <Popup
          trigger={
            <div>
              <Button
                className={'form-button-sked-blue'}
                content={'Install package'}
                onClick={openModal}
                disabled={disableInstallPackages}
                loading={isFetching}
              />
            </div>
          }
          content={'The usePackagingV2 feature flag must be enabled to install packages'}
          basic
          position="top right"
          disabled={!disableInstallPackages}
        />
      }
      onClose={onClose}
      onOpen={openModal}
    >
      <Modal.Header id="modal-header-install-package">Install package</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field required style={{ marginBottom: '30px' }}>
            <div style={{ marginBottom: '10px' }}>Select the type of package to install.</div>
            <Form.Group inline widths="equal">
              {INSTALLATION_OPTIONS.map(installOption => (
                <Form.Field key={installOption.text}>
                  <Button
                    type="button"
                    className={packageName === installOption.text ? 'radio selected' : 'radio'}
                    onClick={() => {
                      setSelectedInstallationOption(installOption.value)
                      setPackageName('')
                      setPackageVersion('')
                    }}
                    basic
                    fluid
                  >
                    <Radio
                      label={<label>{installOption.text}</label>}
                      name={`installOptionCheckboxGroup-${installOption.value}`}
                      value={installOption.text}
                      checked={selectedInstallationOption === installOption.value}
                    />
                  </Button>
                </Form.Field>
              ))}
            </Form.Group>
          </Form.Field>

          {selectedInstallationOption === CORE_PRODUCT_PACKAGE && (
            <Form.Field>
              <Header as="h3">{INSTALLATION_OPTIONS[0].text}</Header>
              <div style={{ marginBottom: '10px' }}>
                Select a package below for installation using the latest version.
              </div>
              <RadioGroup
                options={PRODUCT_PACKAGES}
                name="region"
                value={packageName}
                handleInput={(_event, data) => setPackageName(data.value)}
              />
            </Form.Field>
          )}

          {selectedInstallationOption === CUSTOMISED_PACKAGE && (
            <Form.Field>
              <Header as="h3">{INSTALLATION_OPTIONS[1].text}</Header>
              <Form.Field required>
                <label htmlFor="manual-package-name">Package name</label>
                <Input
                  data-testid="custom-package-name"
                  id="packageName"
                  name="packageName"
                  type="text"
                  placeholder="Enter package name"
                  value={packageName}
                  onChange={(_, { value }) => setPackageName(value)}
                  fluid
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="manual-package-version">Package version</label>
                <div style={{ marginBottom: '0px' }} className="create-team-note">
                  Empty or unspecified package version defaults to the latest available version for the specified
                  package name.
                </div>
                <Input
                  data-testid="custom-package-version"
                  id="version"
                  name="version"
                  type="text"
                  placeholder="Enter package version"
                  value={packageVersion}
                  onChange={(_, { value }) => setPackageVersion(value)}
                  fluid
                />
              </Form.Field>
            </Form.Field>
          )}
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          id="install-package-submit"
          className="form-button-sked-blue"
          content="Submit"
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </Modal.Actions>
    </Modal>
  )
}
