import { SemanticWIDTHS } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useGetTeamQuery } from '../../../../queries/GetTeamQuery'
import { useMobileV3FormService } from '../../services/MobileV3FormService'
import { IMobileV3Form } from '../../../../actions/MobileV3Forms'
import { createMemoizedColumns } from '../../../../components/TableComponents/ReactTable'
import { MobileExtensionsStatus } from './mobileExtensionsConstants'
import { MobileFormStatus } from './MobileFormStatus'
import { MobileFormsListContainer } from './MobileFormsListContainer'

enum MobileV3FormType {
  Job = 'Job',
  Global = 'Global'
}

enum MobileV3FormSource {
  FormBuilder = 'Form builder',
  CustomExtension = 'Custom extension'
}

const FORM_V3_NAME_COLUMN_WIDTH: SemanticWIDTHS = 3
const OTHER_COLUMNS_WIDTH: SemanticWIDTHS = 2
export const EMPTY_LIST_MESSAGE = 'No Skedulo Plus extensions found.'

export const getFormName = (form: IMobileV3Form) => form.name

export const getFormUID = (form: IMobileV3Form) => form.uid

export const getFormSource = (form: IMobileV3Form) =>
  form.metadata.formBuildType === 'builder-survey' ? MobileV3FormSource.FormBuilder : MobileV3FormSource.CustomExtension

export const getFormType = (form: IMobileV3Form) =>
  form.metadata.contextObject === 'Jobs' ? MobileV3FormType.Job : MobileV3FormType.Global

export const getFormStatus = (form: IMobileV3Form) =>
  form.status === MobileExtensionsStatus.Installed ? MobileExtensionsStatus.Published : form.status

interface MobileV3FormsProps {
  teamId: string
}

const MobileV3Forms = ({ teamId }: MobileV3FormsProps) => {
  const { data: teamData, isLoading: loadingTeamData } = useGetTeamQuery(teamId)
  const { mobileV3Forms, isLoading: loadingMobileFormList } = useMobileV3FormService({
    tenantId: teamData?.tenantId ?? '',
    region: teamData?.region ?? ''
  })

  const renderStatusCell = ({ row }: { row: Row<IMobileV3Form> }) => (
    <MobileFormStatus status={getFormStatus(row.original)} />
  )

  const tableColumns = createMemoizedColumns<IMobileV3Form>(
    [
      {
        Header: 'Name',
        width: FORM_V3_NAME_COLUMN_WIDTH,
        accessor: getFormName
      },
      {
        Header: 'Form UID',
        width: OTHER_COLUMNS_WIDTH,
        accessor: getFormUID
      },
      {
        Header: 'Source',
        width: OTHER_COLUMNS_WIDTH,
        accessor: getFormSource
      },
      {
        Header: 'Type',
        width: OTHER_COLUMNS_WIDTH,
        accessor: getFormType
      },
      {
        Header: 'Status',
        width: OTHER_COLUMNS_WIDTH,
        Cell: renderStatusCell
      }
    ],
    []
  )

  return (
    <div style={{ marginBottom: '1rem' }} data-testid="mobile-v3-forms-list">
      <MobileFormsListContainer
        title="Skedulo Plus extensions"
        description="This section shows the list of all Skedulo Plus extensions, including both Form Builder forms and MEX extensions"
        forms={mobileV3Forms}
        isLoading={loadingTeamData || loadingMobileFormList}
        emptyListMessage={EMPTY_LIST_MESSAGE}
        tableColumns={tableColumns}
      />
    </div>
  )
}

export default MobileV3Forms
