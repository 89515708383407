import { IResult } from './Utils'
import { regionalApiServer } from './Endpoints'

export interface IMobileV3Form {
  uid: string
  tenantId: string
  name: string
  status: string
  metadata: {
    contextObject: string
    formBuildType?: string
    [key: string]: any
  }
}

export const getMobileV3FormsByTenantId = (tenantId: string, region: string): Promise<IMobileV3Form[]> =>
  regionalApiServer(region)
    .get<IResult<IMobileV3Form[]>>(`/form/internal/definition/all/${tenantId}`)
    .then(response => response.data.result)
