import { Header } from 'semantic-ui-react'
import { MemoizedColumns, Table } from '../../../../components/TableComponents/ReactTable'

interface MobileFormsListContainerProps<T extends object> {
  title: string
  description: string
  forms: T[]
  isLoading: boolean
  emptyListMessage: string
  tableColumns: MemoizedColumns<T>
}

export const MobileFormsListContainer = <T extends object>({
  title,
  description,
  tableColumns,
  forms,
  isLoading,
  emptyListMessage
}: MobileFormsListContainerProps<T>) => (
  <div>
    <div
      style={{
        paddingTop: '1rem'
      }}
    >
      <Header as="h3">{title}</Header>
      <p>{description}</p>
    </div>
    <Table<T>
      canAdjustColumnWidth
      textAlign="left"
      columns={tableColumns}
      data={forms}
      loading={isLoading}
      emptyMessage={emptyListMessage}
      color="blue"
      celled
    />
  </div>
)
