import MobileV2Form from './MobileV2Forms'
import MobileV3Forms from './MobileV3Forms'

interface MobileExtensionsListProps {
  teamId: string
}

const MobileExtensionsList = ({ teamId }: MobileExtensionsListProps) => (
  <div data-testid="mobile-extensions">
    <MobileV3Forms teamId={teamId} />
    <MobileV2Form teamId={teamId} />
  </div>
)

export default MobileExtensionsList
