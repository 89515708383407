import { IMobileV3Form } from '../../../actions/MobileV3Forms'
import { useGetMobileV3FormListQuery } from '../../../queries/GetMobileV3FormListQuery'

export interface MobileV3FormServiceConfig {
  tenantId: string
  region: string
}

export interface MobileV3FormService {
  mobileV3Forms: IMobileV3Form[]
  isLoading: boolean
}

export const useMobileV3FormService = ({ tenantId, region }: MobileV3FormServiceConfig): MobileV3FormService => {
  const { data: mobileV3Forms, isLoading } = useGetMobileV3FormListQuery(tenantId, region)

  return {
    mobileV3Forms: mobileV3Forms ?? [],
    isLoading
  }
}
